import { useState } from "react";
import { ITableColumn } from "@interfaces/common.interfaces";
import ReportSection from "../../../reports-common/report-section/report-section";
import { IReportSectionProps } from "../../../reports-common/report-section/report-section.props";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import { ISampleInTenantReportDetails, ISampleInTenantReportItem } from "@interfaces/reports/sample-reports.interfaces";
import SampleInTenantReportSummary from "components/reports/sample/sample-in-tenant-report/sample-in-tenant-report-summary";
import { IOrphanedMailboxesInTenantReportDetails, IOrphanedMailboxesInTenantReportItem } from "@interfaces/reports/orphaned-object-reports.interfaces";
import OrphanedMailboxesInTenantReportSummary from "components/reports/orphaned-objects/orphaned-mailboxes-in-tenant-report/orphaned-mailboxes-in-tenant-report-summary";
import PrincipalsCell from "components/reports-common/report-results-grid-cells/principals-cell/principals-cell";
import { escapeForExport } from "lib/utils";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/orphaned-mailboxes-in-tenant";

export default function OrphanedMailboxesInTenantReport() {

  const { t } = useL10n<ILocaleStrings>();
  const [model, setModel] = useState<IRequestModel>({});

  // TODO: Each column defined somewhere central and put together here.
  const cols: Array<ITableColumn<IOrphanedMailboxesInTenantReportItem>> = [
    {
      key: "user", label: t("user"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: any) => (<PrincipalsCell columnId={key} item={item} />),
      getExportValue: (key: string, item: any) => { // TODO: IOrphanedMailboxesInTenantReportItem
        return escapeForExport(item[key]);
      }
    },
    {
      key: "user", label: t("userUpn"), showInGrid: false, showInExport: true,
      getExportValue: (key: string, item: IOrphanedMailboxesInTenantReportItem) => {
        return item.user?.upn;
      }
    },
    {
      key: "user", label: t("entraUserId"), showInGrid: false, showInExport: true,
      getExportValue: (key: string, item: IOrphanedMailboxesInTenantReportItem) => {
        return item.user?.objectId;
      }
    },
    { key: "email", label: t("email"), showInGrid: true, showInExport: true, minWidth: 300, idealWidth: 300 },
    { key: "purpose", label: t("ppaMailboxType"), showInGrid: true, showInExport: true, minWidth: 100 }
  ];

  const isModelValid = (): boolean => {
    return true;
  }

  const reportSectionProps: IReportSectionProps<IOrphanedMailboxesInTenantReportItem, IOrphanedMailboxesInTenantReportDetails> = {
    columns: cols,
    summaryComponent: <OrphanedMailboxesInTenantReportSummary temp="the temp prop" />,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: t("ppaOrphanedUsersInTenantReportNoItemsMessage"),
    exportFilenamePrefix: "OrphanedMailboxesInTenantReport"
  }

  return (
    <ReportSection {...reportSectionProps}>
    </ReportSection>
  );
}
