//import navItems from "lib/nav-items";
//import NavigationCards from "../../../navigation/navigation-cards/navigation-cards";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
//import CommandBar from "@components/command-bar/command-bar";
import CommandBar from "../../../command-bar/command-bar";
import ReportResultsGrid from "../../../reports-common/report-results-grid/report-results-grid";
import ReportInput from "../../../reports-common/report-input/report-input";
import { AxiosError } from "axios";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { Button, Input, InputProps, Label, TableColumnDefinition, createTableColumn, useId } from "@fluentui/react-components";
import { TeamsFxContext } from "context/teams-context";
import { IGuestUsersInWorkspaceReportItem } from "@interfaces/reports/guest-user-reports.interfaces";
import {
  InfoRegular,
  DocumentRegular
} from "@fluentui/react-icons";
import { ICommandBarItem, ITableColumn, IWorkspace } from "@interfaces/common.interfaces";
import ReportSection from "../../../reports-common/report-section/report-section";
import WorkspacePicker from "../../../controls/workspace-picker/workspace-picker";
import { IWorkspacePickerProps } from "../../../controls/workspace-picker/workspace-picker.props";
import { IReportSectionProps } from "../../../reports-common/report-section/report-section.props";
import { PPAContext } from "context/ppa-context";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import Progress from "components/common/progress/progress";
import ReportProgress from "components/reports-common/report-progress/report-progress";
import WorkspacePickerTemp from "components/controls/workspace-picker/workspace-picker-temp";
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";
import PrincipalsCell from "components/reports-common/report-results-grid-cells/principals-cell/principals-cell";
import InvitationsCell from "components/reports-common/report-results-grid-cells/intivations-cell/invitations-cell";
import EnumCell from "components/reports-common/report-results-grid-cells/enum-cell/enum-cell";
import IconCell from "components/reports-common/report-results-grid-cells/icon-cell/icon-cell";
import { ISharedLinksInWorkspaceReportItem } from "@interfaces/reports/shared-link-reports.interfaces";
import IconWithLabel from "components/reports-common/report-results-grid-cells/common/icon-with-label/icon-with-label";
import SharingLinkPermissionsTypeCell from "components/reports-common/report-results-grid-cells/shared-links/sharing-link-permissions-type-cell/sharing-link-permissions-type-cell";
import LinkCell from "components/reports-common/report-results-grid-cells/link-cell/link-cell";
import { escapeForExport } from "lib/utils";
import { getSharingLinkPermissionsTypePrimaryLabel, getSharingLinkPermissionsTypeSecondaryLabel } from "lib/shared-links";
import { IStorageInWorkspaceReportDetails, IStorageInWorkspaceReportItem } from "@interfaces/reports/storage-reports.interfaces";
import SizeCell from "components/reports-common/report-results-grid-cells/size-cell/size-cell";
//import PercentageCell from "components/reports-common/report-results-grid-cells/percentage-cell/percentage-cell";
import StoragePercentageCell from "components/reports-common/report-results-grid-cells/storage-percentage-cell/storage-percentage-cell";
import StorageInWorkspaceReportSummary from "components/reports/storage/storage-in-workspace-report/storage-in-workspace-report-summary";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/storage-in-workspace";

export default function StorageInWorkspaceReport() {

  const { t } = useL10n<ILocaleStrings>();
  const [model, setModel] = useState<IRequestModel>({});
  const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace>();

  // TODO: Each column defined somewhere central and put together here.
  const cols: Array<ITableColumn<IStorageInWorkspaceReportItem>> = [
    {
      key: "folderName", label: t("ppaLibrary"), showInGrid: true, showInExport: true, defaultWidth: 200,
      renderCell: (key: string, item: any) => (<LinkCell label={item.folderName} url={item.folderUrl} />)
    },
    { key: "folderUrl", label: t("ppaLibraryUrl"), showInGrid: false, showInExport: true },
    { key: "folderId", label: "Folder id", showInGrid: false, showInExport: true },
    {
      key: "totalSize", label: t("ppaTotalSize"), showInGrid: true, showInExport: false, defaultWidth: 200,
      renderCell: (key: string, item: any) => (<SizeCell value={item.totalSize} valueUnits="B" />)
    },
    { key: "totalSize", label: t("ppaTotalSizeBytes"), showInGrid: false, showInExport: true },
    {
      key: "percentageOfSiteUsage", label: t("ppaPercentageOfSiteUsage"), showInGrid: true, showInExport: true, defaultWidth: 200,
      renderCell: (key: string, item: IStorageInWorkspaceReportItem, details: IStorageInWorkspaceReportDetails) => (
        <StoragePercentageCell
          percentageUsed={item.percentageOfSiteUsage}
          usedValue={item.totalSize}
          usedValueUnits="B"
          //maxValue={item.siteTotalSize}
          maxValue={details.siteTotalSizeKB}
          maxValueUnits="KB" />)
    },
    {
      key: "percentageOfQuota", label: t("ppaPercentageOfQuota"), showInGrid: true, showInExport: true, defaultWidth: 200,
      renderCell: (key: string, item: IStorageInWorkspaceReportItem, details: IStorageInWorkspaceReportDetails) => (
        <StoragePercentageCell
          percentageUsed={item.percentageOfQuota}
          usedValue={item.totalSize}
          usedValueUnits="B"
          maxValue={details.siteQuotaMaximumMB}
          maxValueUnits="MB" />)
    },
    { key: "totalFileCount", label: t("ppaFileCount"), showInGrid: true, showInExport: true, defaultWidth: 200 },
    {
      key: "lastModified", label: t("lastModified"), showInGrid: true, showInExport: true,
      renderCell: (key: string, item: any) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
    }
    // Total size
    // File count
    // TODO: WHAT ABOUT ATTACHMENTS IN LISTS????
    // % of site usage
    // % of quota
    // Last modified
  ];

  const onWorkspaceChange = (value?: IWorkspace) => {
    console.log(`StorageInWorkspaceReport -> onWorkspaceChange -> `, value);
    setModel({ ...model, workspace: { siteUrl: value?.siteUrl } });
    setSelectedWorkspace(value);
  }

  const isModelValid = (): boolean => {
    return model.workspace?.siteUrl ? true : false;
  }

  const reportSectionProps: IReportSectionProps<IStorageInWorkspaceReportItem, IStorageInWorkspaceReportDetails> = {
    columns: cols,
    summaryComponent: <StorageInWorkspaceReportSummary temp="the temp prop" />,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: t("ppaStorageInWorkspaceReportNoItemsMessage"),
    exportFilenamePrefix: "StorageInWorkspaceReport"
  }

  return (
    <ReportSection {...reportSectionProps}>
      <WorkspacePicker onChange={onWorkspaceChange} model={selectedWorkspace} />
    </ReportSection>
  );
}
