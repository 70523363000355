import { PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { Spinner, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    InfoRegular,
    FilterRegular
} from "@fluentui/react-icons";
import { TeamsFxContext } from "context/teams-context";
import { PPAContext } from "context/ppa-context";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { IReportRequestsProps } from "./report-requests.props";
import CommandBar from "components/command-bar/command-bar";
import { IReportRequest } from "@interfaces/reports/reports-common.interfaces";
import { ICommandBarItem, ITableColumn } from "@interfaces/common.interfaces";
import NavigateCell from "../report-results-grid-cells/navigate-cell/navigate-cell";
import IconWithLabel from "../report-results-grid-cells/common/icon-with-label/icon-with-label";
import { getReportRequestStatusIcon } from "lib/report-requests";
import PercentageBar from "components/common/percentage-bar/percentage-bar";
import PrincipalsCell from "../report-results-grid-cells/principals-cell/principals-cell";
import DateTimeCell from "../report-results-grid-cells/date-time-cell/date-time-cell";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import Grid from "components/grid/grid";
import ReportRequestsGrid from "../report-requests-grid/report-requests-grid";

const defaultCommandBarItems: Array<ICommandBarItem> = [
    {
        id: 'openFilter',
        placement: 'right',
        type: 'button',
        order: 1,
        disabled: true,
        icon: <FilterRegular />
    }
];

const layoutStyles = makeStyles({
    mainWrapper: {
        //...shorthands.borderWidth('1px', '0'),
    },
    spinner: {
        ...shorthands.margin('2em', 0)
    }
});

export default function ReportRequests(props: IReportRequestsProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    const ppa = useContext(PPAContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [reportRequests, setReportRequests] = useState<Array<IReportRequest>>([]);
    const [checkCount, setCheckCount] = useState<number>(0);

    // Create the api client for each of the api calls.
    const apiClient = useMemo(() => {
        return createApiClient(
            ppa.appInstanceApiUrl || "",
            new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
        );
    }, [ppa.appInstanceApiUrl]);

    const getReportRequests = async (): Promise<Array<any>> => {
        try {
            const response = await apiClient.get<Array<IReportRequest>>(`/api/report-requests`);

            const temp = response.data.map((item: IReportRequest) => {
                // TODO: Remove this when the ppa getReportPageUrl method is no longer optional!!
                const url = ppa.getReportPageUrl ? ppa.getReportPageUrl(item.reportType) : '/';
                const reportName = ppa.t ? ppa.t(item.reportType) : item.reportType;

                return {
                    ...item,
                    reportRequestUrl: `${url}/${item.requestId}`,
                    reportName: reportName
                };
            });

            setReportRequests(temp);
            setCheckCount(checkCount + 1);
            setLoading(false);
            return temp;
        }
        catch (ex: unknown) {
            setLoading(false);
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    useEffect(() => {
        setLoading(true);
        getReportRequests();
    }, []);

    useEffect(() => {
        // 
        var timerId: NodeJS.Timeout;
        const hasProcessingRequests: boolean = reportRequests.filter(r => r.status !== 3 && r.status !== 4).length > 0;
        const timeOut = hasProcessingRequests ? 2000 : 10000;

        timerId = setTimeout(() => {
            getReportRequests();
        }, timeOut);

        return () => clearTimeout(timerId);
    }, [checkCount]);


    return (
        <>
            <CommandBar items={defaultCommandBarItems} />
            {loading &&
                <Spinner
                    className={styles.spinner}
                    labelPosition="below"
                    label={ppa.t ? `${ppa.t('loading')}...` : ''} />}
            {!loading &&
                <section className={styles.mainWrapper} style={{ flexGrow: 1, display: 'flex', overflow: 'hidden', position: 'relative' }}>
                    <ReportRequestsGrid
                        items={reportRequests}
                        onSelectionChanged={(items: Array<IReportRequest>) => { }} />
                </section>}
        </>
    );
}