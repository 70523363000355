//import navItems from "lib/nav-items";
//import NavigationCards from "../../../navigation/navigation-cards/navigation-cards";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
//import CommandBar from "@components/command-bar/command-bar";
import CommandBar from "../../../command-bar/command-bar";
import ReportResultsGrid from "../../../reports-common/report-results-grid/report-results-grid";
import ReportInput from "../../../reports-common/report-input/report-input";
import { AxiosError } from "axios";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { Button, Input, InputProps, Label, TableColumnDefinition, createTableColumn, useId } from "@fluentui/react-components";
import { TeamsFxContext } from "context/teams-context";
import { IGuestUsersInWorkspaceReportDetails, IGuestUsersInWorkspaceReportItem } from "@interfaces/reports/guest-user-reports.interfaces";
import {
  InfoRegular
} from "@fluentui/react-icons";
import { ICommandBarItem, ITableColumn, IWorkspace } from "@interfaces/common.interfaces";
import ReportSection from "../../../reports-common/report-section/report-section";
import WorkspacePicker from "../../../controls/workspace-picker/workspace-picker";
import { IWorkspacePickerProps } from "../../../controls/workspace-picker/workspace-picker.props";
import { IReportSectionProps } from "../../../reports-common/report-section/report-section.props";
import { PPAContext } from "context/ppa-context";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import Progress from "components/common/progress/progress";
import ReportProgress from "components/reports-common/report-progress/report-progress";
import WorkspacePickerTemp from "components/controls/workspace-picker/workspace-picker-temp";
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
//import { TempContext } from "context/tempContext";

// Land on page - Show the input panel
// Submit request - show spinner on input panel
// If error returned - show error on input panel
// If results returned - hide input panel and show grid

// PoC.1 - Basic bits
// ====================================
// 1. api endpoint basic with temp data
// 2. basic input
// 3. call the api
// 4. handle the error result
// 5. handle the success result
// 6. send another query (show previous query value in panel)
// ====================================

// PoC.2 - UI
// ==================================================
//// 1. Get AppInstance from somewhere central??
//// 2. Inline drawer
//// 4. Breadcrumb at least basic
//// 5. Commandbar at least basic
//// 6. Loading spinners/shimmer
//// 7. Home screen/cards container padding
//// 9. MUI grid in content now has horizontal scroll
//// 10. Full buttons in command bar
//// 11. Commandbar buttons status. e.g. export when no results.
//// 12. Navitems as flattened array.
//// 13. Incorporate Index getting signup into PPAContext.
//// 14. Proper type for signup and tenant
// ==================================================

//// DEMO

// PoC.3 - Api data
// ==================================================
//// 1. Queue request and return status object (temp data)
//// 2. Some sort of listener?
//// 3. Get status until complete
//// 4. Get report data when complete (maybe this actually gets stored in blob storage as json? with a lifetime on it...)
//// 5. Queue Service and Azure resource
//// 6. Store in Blob storage
//// 7. Queue Processor
//// 8. Display results
//// 9. Proper input model in UI
//// 10. Progress indicator
//// 11. Run report again
// ==================================================

// DEMO

// PoC.4 - Get workspaces for input filter
// ==================================================
// 1. ...
// ==================================================

// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/guest-users-in-workspace";

// TODO: Api to return the proper column values as true/false, etc


// showUnchecked defaults to false.
// isChecked is a callback that can have the logic to return true/false.
// <CheckBoxCell columnId item checkedValue showUnchecked isChecked />
// <CheckBoxCell columnId={key} item={item} checkedValue="PendingAcceptance" />

export default function GuestUsersInWorkspaceReport() {

  const { t } = useL10n<ILocaleStrings>();
  const [model, setModel] = useState<IRequestModel>({});
  const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace>();

  // TODO: Each column defined somewhere central and put together here.
  const cols: Array<ITableColumn<IGuestUsersInWorkspaceReportItem>> = [
    { key: "displayName", label: t("displayName"), showInGrid: true, showInExport: true, minWidth: 100 },
    { key: "email", label: t("email"), showInGrid: true, showInExport: true, minWidth: 100, idealWidth: 200 },
    { key: "upn", label: t("upn"), showInGrid: false, showInExport: true, minWidth: 100, idealWidth: 300 },
    { key: "objectId", label: t("entraUserId"), showInGrid: false, showInExport: true },
    //{ key: "memberOfType", label: "Type", showInGrid: true, showInExport: true, minWidth: 100 }, // TODO: Maybe this is an icon column?
    { key: "memberOfRole", label: t("role"), showInGrid: true, showInExport: true, minWidth: 100 },
    //{ key: "customPermission", label: "Custom permission", showInGrid: true, showInExport: true, minWidth: 150 },
    { key: "created", label: t("created"), showInGrid: true, showInExport: true, renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />) },
    { key: "deleted", label: t("deleted"), showInGrid: false, showInExport: true, renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />) },
    { key: "orphaned", label: t("orphaned"), showInGrid: true, showInExport: true },
    { key: "pendingAcceptance", label: t("pending"), showInGrid: true, showInExport: true, renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />) },
    { key: "accountDisabled", label: t("disabled"), showInGrid: true, showInExport: true, renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />) },
    { key: "lastLogin", label: t("lastLogin"), showInGrid: false, showInExport: false } // TODO: Only if data available/P1 licence. Maybe a flag in settings? Or check the tenant settings for P1 lic?
  ];

  const onWorkspaceChange = (value?: IWorkspace) => {
    console.log(`GuestUsersInWorkspaceReport -> onWorkspaceChange -> `, value);
    setModel({ ...model, workspace: { siteUrl: value?.siteUrl } });
    setSelectedWorkspace(value);
  }

  const isModelValid = (): boolean => {
    return model.workspace?.siteUrl ? true : false;
  }

  const reportSectionProps: IReportSectionProps<IGuestUsersInWorkspaceReportItem, IGuestUsersInWorkspaceReportDetails> = {
    columns: cols,
    summaryComponent: <></>,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: t("ppaGuestUsersInWorkspaceReportNoItemsMessage"),
    exportFilenamePrefix: "GuestUsersInWorkspaceReport"
  }

  return (
    <ReportSection {...reportSectionProps}>
      <WorkspacePicker onChange={onWorkspaceChange} model={selectedWorkspace} />
    </ReportSection>
  );
}
