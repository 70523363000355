import {
    OpenRegular,
    NotepadPersonRegular,
    GuestRegular,
    GlobePersonRegular,
    PersonRegular,
    StorageRegular,
    DocumentOnePageLinkRegular,
    ShareRegular,
    FolderGlobeRegular,
    DocumentDatabaseRegular,
    HomeDatabaseRegular,
    DatabaseRegular,
    PersonQuestionMarkRegular,
    DocumentSearchRegular,
    MailProhibitedRegular
} from "@fluentui/react-icons";
import { INavItemV3 } from "@interfaces/common.interfaces";


const navItems: Array<INavItemV3> = [
    {
        id: "1",
        route: "/reports",
        label: "ppaReportsTitle",
        //description: "These are the reports available to run in your tenant.",
        description: "ppaReportsDescription",
        icon: DocumentSearchRegular,
        //isReport: false,
        //enabled: true
        onTopNav: true
    },
    {
        id: "1.1",
        route: "/reports/guest-users",
        label: "ppaGuestUsersReportGroupTitle",
        //description: "These reports will identify the guest users in your tenant and the areas they have access to.",
        description: "ppaGuestUsersReportGroupDescription",
        icon: GuestRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1"
    },
    {
        id: "1.1.1",
        route: "/reports/guest-users/guest-users-tenant",
        label: "ppaGuestUsersInTenantReportTitle",
        //description: "Reports on all the guest users across the M365 tenant and the role they have been assigned.",
        description: "ppaGuestUsersInTenantReportDescription",
        icon: GlobePersonRegular,
        //isReport: true,
        //enabled: true,
        parentId: "1.1"
    },
    {
        id: "1.1.2",
        route: "/reports/guest-users/guest-users-workspace",
        label: "ppaGuestUsersInWorkspaceReportTitle",
        //description: "Reports on the guest users in a specific workspace and the role they have been assigned.",
        description: "ppaGuestUsersInWorkspaceReportDescription",
        icon: NotepadPersonRegular,
        //isReport: true,
        //enabled: true,
        parentId: "1.1"
    },
    {
        id: "1.1.3",
        route: "/reports/guest-users/guest-users-user",
        label: "ppaGuestUserReportTitle",
        //description: "Reports on the permissions assigned to a specific guest user across all the workspaces in the M365 tenant.",
        description: "ppaGuestUserReportDescription",
        icon: PersonRegular,
        //isReport: true,
        //enabled: true,
        parentId: "1.1"
    },
    {
        id: "1.2",
        route: "/reports/sharing-links",
        label: "ppaSharingLinksReportGroupTitle",
        //description: "The reports for sharing links in the tenant.",
        description: "ppaSharingLinksReportGroupDescription",
        icon: OpenRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1",
        disabled: false
    },
    {
        id: "1.2.1",
        route: "/reports/sharing-links/sharing-links-workspace",
        label: "ppaSharingLinksInWorkspaceReportTitle",
        //description: "Reports on the sharing links in a specific workspace and who they have been shared with.",
        description: "ppaSharingLinksInWorkspaceReportDescription",
        icon: DocumentOnePageLinkRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.2",
        disabled: false
    },
    {
        id: "1.2.2",
        route: "/reports/sharing-links/sharing-links-tenant",
        label: "ppaSharingLinksInTenantReportTitle",
        //description: "Reports on all the sharing links across the M365 tenant.",
        description: "ppaSharingLinksInTenantReportDescription",
        icon: ShareRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.2",
        disabled: false
    },
    {
        id: "1.3",
        route: "/reports/storage",
        label: "ppaStorageReportGroupTitle",
        //description: "The reports on storage capacity and usage in the tenant.",
        description: "ppaStorageReportGroupDescription",
        icon: DatabaseRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1",
        disabled: false
    },
    {
        id: "1.3.1",
        route: "/reports/storage/storage-workspace",
        label: "ppaStorageInWorkspaceReportTitle",
        //description: "Reports on the storage capacity and usage in a specific workspace.",
        description: "ppaStorageInWorkspaceReportDescription",
        icon: DocumentDatabaseRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.3",
        disabled: false
    },
    {
        id: "1.3.2",
        route: "/reports/storage/storage-tenant",
        label: "ppaStorageInTenantReportTitle",
        //description: "Reports on the storage capacity and usage across the M365 tenant.",
        description: "ppaStorageInTenantReportDescription",
        icon: HomeDatabaseRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.3",
        disabled: false
    },
    {
        id: "1.4",
        route: "/reports/orphaned-objects",
        label: "ppaOrphanedObjectsReportGroupTitle",
        //description: "The reports on orphaned objects across the tenant.",
        description: "ppaOrphanedObjectsReportGroupDescription",
        icon: PersonQuestionMarkRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1",
        disabled: false
    },
    {
        id: "1.4.1",
        route: "/reports/orphaned-objects/orphaned-users-tenant",
        label: "ppaOrphanedUsersInTenantReportTitle",
        //description: "Reports on the orphaned users in SharePoint sites across the M365 tenant.",
        description: "ppaOrphanedUsersInTenantReportDescription",
        icon: PersonQuestionMarkRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.4",
        disabled: false
    },
    {
        id: "1.4.2",
        route: "/reports/orphaned-objects/orphaned-mailboxes-tenant",
        label: "ppaOrphanedMailboxesInTenantReportTitle",
        //description: "Reports on the orphaned mailboxes in Exchange across the M365 tenant.<br/>An orhpaned mailbox is a mailbox that is assigned to a single user and the user is disabled in Entra ID.",
        description: "ppaOrphanedMailboxesInTenantReportDescription",
        icon: MailProhibitedRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.4",
        disabled: false
    },
    // {
    //     id: "2",
    //     route: "/actions",
    //     label: "ppaActionsTitle",
    //     description: "You can perform certain actions.",
    //     //showAsCard: true
    //     //onTopNav: true
    //     disabled: true
    // },
    {
        id: "3",
        route: "/report-requests",
        label: "ppaReportRequestsTitle",
        //onTopNav: true
        disabled: true
    },
    {
        id: "4",
        route: "/action-requests",
        label: "ppaActionRequestsTitle",
        //onTopNav: true
        disabled: true
    },
    {
        id: "5",
        route: "/about",
        label: "about",
        //onTopNav: true
        disabled: true
    }
]

export default navItems;